import React from 'react';
import Image from './Image';

export default function PostContent({ content, type }) {
  return <div className="post-content max-w-2xl mx-auto">{content && content.map((section, index) => <RenderFlexibleSection key={`${section.__typename}-${index}`} type={type} section={section} />)}</div>;
}

const RenderFlexibleSection = ({ section, type }) => {
  switch (section.__typename) {
    case `${type}_Content_Text`:
      return <div dangerouslySetInnerHTML={{ __html: section.text }} />;
    case `${type}_Content_Image`:
      return (
        <figure className="py-6 md:pr-3">
          <Image data={section.image} />
        </figure>
      );
    case `${type}_Content_Testimonial`:
      return (
        <section className="py-6">
          <div className="bg-tertiary md:-ml-16 -mx-6 relative">
            <div className="absolute right-0 mr-4 -mt-4">
              <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <path fill="#0D0D0D" d="M0 0H56.666V56.666H0z" />
                  <path d="M26.91 35.6v-7.622H22.44L27.063 20h-3.405l-4.726 7.978V35.6h7.978zm10.671 0v-7.622H33.11L37.734 20h-3.456l-4.675 7.978V35.6h7.978z" fill="#FFF" fillRule="nonzero" />
                </g>
              </svg>
            </div>

            <div className="px-6 md:px-16 py-12">
              {section.logo && (
                <div className="mb-8" style={{ maxWidth: '100px' }}>
                  <Image data={section.logo} />
                </div>
              )}
              <blockquote className="text-28px font-medium sm:mr-6 max-w-lg">{section.quote}</blockquote>
              <div className="mt-6">
                <div className="font-semibold">{section.citeName}</div>
                <div className="mt-1 font-medium text-16px">{section.citeRole}</div>
              </div>
            </div>
          </div>
        </section>
      );

    default:
      return null;
  }
};

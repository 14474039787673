import React from 'react';

const TwoColumns = ({ id, className, hasContainer, grid, gutters, reverse, text, imageContainerClass, image }) => (
  <section id={id} className={`${className || ''}`}>
    <div className={`${hasContainer ? 'container' : ''} `}>
      <div className={`${grid || 'grid md:grid-cols-2 items-center'} ${gutters || 'gap-12 md:gap-16'} `}>
        <div className={reverse ? 'md:order-1' : ''}>{text}</div>
        <div className={`flex flex-col justify-center ${imageContainerClass || ''}`}>{image}</div>
      </div>
    </div>
  </section>
);

export default TwoColumns;

import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostContent from '../components/PostContent';
import FeaturedCustomer from '../components/FeaturedCustomer';

export default class Customer extends Component {
  render() {
    const { seo } = this.props.data.wpCustomer;
    const { hero, stats, content } = this.props.data.wpCustomer.customerPost;
    return (
      <Layout>
        <SEO seo={seo} />
        <FeaturedCustomer
          showStats={true}
          textContainerSize="md"
          sectionPadding={`my-20`}
          statsPadding={`my-28`}
          customer={{
            textCard: {
              heading: hero.heading,
              content: hero.content,
            },
            image: hero.image,
            logo: hero.logo,
            stats,
          }}
        />

        <section className="customer-content my-20">
          <div className="container">
            <PostContent content={content} type="WpCustomer_Customerpost" />
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query Customer($id: String!) {
    wpCustomer(id: { eq: $id }) {
      id
      title
      uri
      ...CustomerSEO
      customerPost {
        hero {
          content
          heading
          logo {
            ...Image
          }
          image {
            ...Image
          }
        }
        stats {
          stat
          statDescription
        }
        content {
          __typename
          ... on WpCustomer_Customerpost_Content_Text {
            text
          }
          ... on WpCustomer_Customerpost_Content_Image {
            image {
              ...Image
            }
            caption
          }
          ... on WpCustomer_Customerpost_Content_Testimonial {
            logo {
              ...Image
            }
            citeName
            citeRole
            quote
          }
        }
      }
    }
  }
`;
